import {createSlice} from '@reduxjs/toolkit';

function createInitialState() {
    return {
        documentShare: null,
        user: null,
        documents: [],
    };
}

export const clientFileSlice = createSlice({
    name: 'clientFile',
    initialState: createInitialState(),
    reducers: {
        succeedDocumentsFetch: (state, action) => {
            state.documents = action.payload.documents;
        },
        succeedDocumentShareFetch: (state, action) => {
            state.documentShare = action.payload;
        },
        succeedUserFetch: (state, action) => {
            state.user = action.payload;
        },
        clearClientFile: (state, action) => {
            return createInitialState();
        }
    },
});

export const {succeedDocumentsFetch, succeedDocumentShareFetch, succeedUserFetch, clearClientFile} = clientFileSlice.actions;

export const selectDocuments = state => state.clientFile.documents;
export const selectDocumentShare = state => state.clientFile.documentShare;
export const selectUser = state => state.clientFile.user;

export default clientFileSlice.reducer;
