import {Typography} from '@material-ui/core';
import PasswordResetForm from '../../features/Login/PasswordResetForm';
import {makeStyles} from '@material-ui/core/styles';
import useQuery from '../../utils/useQuery';
import {useClient} from '../../app/Ged';
import {Alert, AlertTitle} from '@material-ui/lab';
import Link from '../../component/Link/Link';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(8, 'auto'),
        maxWidth: 800,
    },
    form: {
        margin: theme.spacing(6, 'auto')
    }
}));

export default function ActivatePage() {
    const classes = useStyles();
    const queryParams = useQuery();
    const {request, error, isLoading} = useClient();
    const [complete, setComplete] = useState(false);
    const history = useHistory();

    if (!queryParams.has('token') || !queryParams.has('uguid')) {
        return <>
            <h1>Activation de votre compte</h1>
            <Alert severity="error">
                <AlertTitle>
                    Les paramètres pour activer votre compte sont manquants. <br/>
                    <Link to="/">Cliquez-ici pour retourner à l'accueil</Link>
                </AlertTitle>
            </Alert>
        </>
    }

    const activateUser = async (data) => {
        data.token = queryParams.get('token')

        const response = await request({
            method: 'POST',
            url: `/api/v2/users/${queryParams.get('uguid')}/activate`,
            data,
        });

        console.log(response);

        if (response) {
            setComplete(true);
            setTimeout(() => {
                history.push('/');
            }, 8000);
        }

        return false;
    }

    const callbackDoneMessage = <>
        Votre compte a bien été activé ! Vous pouvez vous connecter dès à présent !<br/>
        Vous allez être redirigé sur la page de connexion d'ici quelques secondes.
    </>;

    return <div className={classes.root}>
        <h1>Activation de votre compte</h1>

        {
            complete ?
                <Alert severity="success"><AlertTitle>{callbackDoneMessage}</AlertTitle></Alert>
                :
                <>
                    <Typography>
                        Afin de compléter la création de votre compte, enregistrez votre mot de passe.
                    </Typography>

                    <div className={classes.form}>
                        <PasswordResetForm callback={activateUser} error={error} isLoading={isLoading}/>
                    </div>
                </>
        }

        <p style={{textAlign: 'center'}}>
            <Link to="/" size="sm">Retour au formulaire de connexion</Link>
        </p>

    </div>;
}
