import {useDispatch, useSelector} from 'react-redux';
import {selectDocuments, selectDocumentShare, succeedDocumentsFetch} from './clientFileSlice';
import {useClient} from '../../app/Ged';
import {useCallback} from 'react';
import {findOneMetafieldById} from '../../utils/MetafieldsHelper';
import moment from 'moment';
import {selectSettings} from "../Settings/settingsSlice";

function getDocumentName(document) {
    const name = findOneMetafieldById(document.metafields, 4, ['value', 'label']);

    if (!name) {
        console.warn('Missing metafield with id `4` for document.', document);
        return document.name;
    }

    return name;
}

function getDocumentImportDate(document) {
    const date = document.created_at;

    if (!date) {
        console.error('Missing created_at field in document.', document);
        return null;
    }

    return moment(date, "YYYY-MM-DD").format('DD/MM/YYYY');
}

export function normalizeDocuments(documentsBrut, filteredMetafieldValue) {
    let documents = documentsBrut;

    if (filteredMetafieldValue) {
        documents = Object.entries(filteredMetafieldValue).reduce((acc, [metafieldGuid, metafieldValues]) => (
            [
                ...acc,
                ...documentsBrut
                    .filter(document => metafieldValues
                        .includes(document.metafields
                            .filter(metafield => metafield.guid === metafieldGuid)
                            .map(({value}) => value.value)[0]
                        )
                    )
            ]
        ), []);
    }

    return documents
        .filter(doc => doc.has_content)
        .map(doc => {
            const type = getDocumentName(doc);
            const date = getDocumentImportDate(doc);

            return {
                id: doc.id,
                name: type,
                filename: doc.name,
                guid: doc.guid,
                date: date,
                deletable: doc.deletable,
            };
        });
}

const useDocuments = () => {
    const documents = useSelector(selectDocuments);
    const documentShare = useSelector(selectDocumentShare);
    const {isLoading, error, request} = useClient();
    const dispatch = useDispatch();
    const settings = useSelector(selectSettings);

    const fetchDocuments = async () => {
        const response = await request({
            url: `/api/v2/document_shares/${documentShare.guid}/documents?deleted=0`,
            method: 'get',
        });

        if (!response) {
            return;
        }

        const documents = normalizeDocuments(response.data.items, settings?.filtered_metafield_value);
        dispatch(succeedDocumentsFetch({documents}));
    };

    return {
        documents,
        isLoading,
        error,
        request,
        fetchDocuments: useCallback(fetchDocuments, [dispatch, documentShare.guid, request, settings]),
    }
}

export default useDocuments;
