import {Button, TextField, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useState} from 'react';
import {Alert, AlertTitle} from '@material-ui/lab';
import {useDispatch, useSelector} from 'react-redux';
import {parseLoginResponse, selectLoginError, succeedAuthentication} from './loginSlice';
import {useClient} from '../../app/Ged';
import Link from '../../component/Link/Link';
import {parseValueInText} from "../../utils/stringFormat";
import {selectSettings} from "../Settings/settingsSlice";

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
    },
    form: {
        flexGrow: 1,
        maxWidth: 500,
        margin: theme.spacing(4, 'auto'),
        [theme.breakpoints.up('md')]: {
            // minWidth: 900,
            maxWidth: 900,
        },
    },
    fieldset: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'center',
        },
    },
    formItemWrapper: {
        margin: theme.spacing(2, 'auto'),
        [theme.breakpoints.up('md')]: {
            marginRight: theme.spacing(2),
            flex: 2,
        },
    },
    formItemWrapperButton: {
        margin: theme.spacing(2, 'auto'),
        [theme.breakpoints.up('md')]: {
            flex: 'auto',
            display: 'flex',
            alignItems: 'center',
        },
    },
    formItem: {
    },
    button: {
        [theme.breakpoints.up('md')]: {
            flex: 1,
        },
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        '@media (min-height: 500px)': {
            padding: '10vh 0 2vh',
        },
    },
    link: {
        fontSize: '80%',
    },
    contentWrapper: {
        flex: 1,
    },
    alert: {
        marginTop: theme.spacing(2),
    },
}));

function Login() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const {request, isLoading, error} = useClient();
    const loginError = useSelector(selectLoginError);
    const settings = useSelector(selectSettings);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const authToken = await request({
            method: 'post',
            url: '/api/v2/authenticate',
            data: {username, password, grant_type: 'ng_user'},
        });

        if (authToken && authToken.data) {
            await localStorage.setItem('auth_token', JSON.stringify(authToken.data));
            dispatch(succeedAuthentication(parseLoginResponse(authToken.data)));
        }

        return false;
    }

    return <div className={classes.root}>
        <div>
            <section className={classes.content}>
                <div>
                    <Typography>
                        {
                            parseValueInText(
                                settings.login_message,
                                {"company_name": settings.company_name}
                            )
                        }
                    </Typography>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <div className={classes.fieldset}>
                            <div className={classes.formItemWrapper}>
                                <TextField
                                    id="command_number"
                                    label={settings.document_share_name_input_label}
                                    variant="outlined"
                                    className={classes.formItem}
                                    required
                                    disabled={isLoading}
                                    value={username || ''}
                                    onChange={e => setUsername(e.target.value)}
                                    autoComplete="username"
                                    placeholder="ex: 458996352AZ"/>
                            </div>
                            <div className={classes.formItemWrapper}>
                                <TextField
                                    id="password"
                                    label="Saisissez votre mot de passe"
                                    variant="outlined"
                                    type="password"
                                    className={classes.formItem}
                                    required
                                    disabled={isLoading}
                                    value={password || ''}
                                    onChange={e => setPassword(e.target.value)}
                                    autoComplete="current-password"
                                    placeholder="********"
                                />
                            </div>
                            <div className={classes.formItemWrapperButton}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    className={classes.button}
                                    disabled={isLoading || !username || !username.length || !password || !password.length}
                                    role="login-submit"
                                    fullWidth>
                                    Se connecter
                                </Button>
                            </div>



                        </div>
                        {
                            error &&
                            <Alert severity="error">
                                <AlertTitle>{error}</AlertTitle>
                            </Alert>
                        }
                        {
                            loginError &&
                            <Alert severity="error">
                                <AlertTitle>{loginError}</AlertTitle>
                            </Alert>
                        }
                    </form>
                </div>
            </section>
            <p>
                <Link to="/mot-de-passe-oublié" size="sm">J'ai oublié mon mot de passe</Link>
            </p>
        </div>
    </div>;
}

export default Login;
