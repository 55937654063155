import {createSlice} from '@reduxjs/toolkit';

const createInitialState = () => ({
    logo_url: null,
    favicon_url: null,
    background_image_url: null,
    company_name: null,
    colors: {
        primary: "#EA0909",
        secondary: "#A2A2A6",
        bg_light: "#F7F8F9",
        bg_dark: "#F7F8F9",
        success: "#9effa3",
        warning: "#ffd67c",
        error: "#ff7070",
        info: "#9595ff"
    },
    document_type_list: null,
    document_share_name_input_label: "Saisissez votre référence dossier",
    welcome_message: null,
    file_info: null,
    filtered_metafield_value: null,
    login_message: "Bienvenue sur votre Portail Client",
    footer_logo_url: null,
});

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: createInitialState(),
    reducers: {
        setSettings: (state, action) => {
            return action.payload.settings;
        }
    },
});

export const {setSettings} = settingsSlice.actions;

export const selectSettings = state => state.settings;

export default settingsSlice.reducer;
