import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel, MenuItem, Select} from '@material-ui/core';
import {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useClient} from '../../app/Ged';
import {Alert} from '@material-ui/lab';
import useDocumentShare from './useDocumentShare';
import {useSelector} from 'react-redux';
import {selectSettings} from '../Settings/settingsSlice';

const useStyle = makeStyles(theme => ({
    formControl: {
        width: '100%',
        marginBottom: theme.spacing(4),
    },
    fileInputWrapper: {
        display: 'flex',
    },
    fileInputLabel: {
        flex: 1,
    },
    fileInputButton: {
        display: 'flex',
        alignItems: 'flex-end',
        '& label' : {

        }
    },
}));

function FileForm({reloadDocuments}) {
    const classes = useStyle();
    const {documentShare} = useDocumentShare();
    const [type, setType] = useState(null);
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [progress, setProgress] = useState(null);
    const {request, isLoading, error, reset} = useClient();
    const settings = useSelector(selectSettings);
    const fileTypes = settings.document_type_list;
    const hasFileTypes = fileTypes?.length;

    function handleFileSelected(files) {
        if (files.length > 0) {
            setFile(files[0]);
        }
    }

    function handleClose() {
        setType(null);
        setFile(null);
        setOpen(false);
        setIsUploading(false);
        setProgress(null);
        reset();
    }

    async function handleSubmit() {
        if ((hasFileTypes && !type) || !file) {
            return;
        }

        setIsUploading(true);

        const firstResponse = await request({
            method: 'POST',
            url: '/api/v2/documents',
            data: {
                name: file.name,
                size: file.size,
                document_share_guid: documentShare.guid,
                metafields: type ? {4: type} : {},
            },
        });

        if (!firstResponse) {
            setIsUploading(false);
            return;
        }

        const formData = new FormData();
        formData.append('contents', file);

        setIsUploading(true);
        await request({
            method: 'POST',
            url: `/api/v2/documents/${firstResponse.data.guid}/contents?run_validation=1`,
            data: formData,
            headers: {
                'Content-type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
                setProgress(progressEvent.loaded / progressEvent.total * 100);
            }
        });

        await request({
            method: 'POST',
            url: `/api/v2/document_shares/${documentShare.guid}/run_validation`
        });

        handleClose();
        reloadDocuments();
    }

    return <>
        <div style={{textAlign: 'right', marginBottom: '1em'}}>
            <Button variant={'contained'} color={'primary'} onClick={e => setOpen(true)}>Joindre un document</Button>
        </div>
        <Dialog open={open} onClose={e => handleClose()} maxWidth={'sm'} fullWidth={true}>
            <DialogTitle id="simple-dialog-title">Joindre un document</DialogTitle>
            {error && <Alert severity="error">{error}</Alert>}
            <DialogContent>
                {hasFileTypes &&
                    <FormControl className={classes.formControl}>
                        <InputLabel required={true}>Type de fichier</InputLabel>
                        <Select
                            id="fileType"
                            value={type || ''}
                            onChange={e => setType(e.target.value)}
                            displayEmpty
                            disabled={isLoading || isUploading}
                        >
                            {fileTypes?.map(
                                fileType =>
                                    <MenuItem key={fileType.value} value={fileType.value}>
                                        {fileType.label}
                                    </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                }
                <div className={`${classes.fileInputWrapper} ${classes.formControl}`}>
                    <div className={classes.fileInputLabel}>
                        <FormControl>
                            <InputLabel required={true}>Importer un fichier</InputLabel>
                            <Input
                                id="file-selection-input2"
                                type="text"
                                value={file ? file.name : ''}
                                readOnly={true}
                                disabled={isLoading || isUploading}
                            />
                        </FormControl>
                    </div>
                    <div className={classes.fileInputButton}>
                        <Button variant="contained" component="label" size="small" disabled={isLoading || isUploading}>
                            {file ? 'Changer de fichier' : 'Choisir un fichier'}
                            <input type="file" hidden onChange={e => handleFileSelected(e.target.files)}/>
                        </Button>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                {isUploading && <CircularProgress variant="determinate" value={progress} />}
                {isLoading && <CircularProgress />}
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    disabled={
                        file === null ||
                        (hasFileTypes && type === null) ||
                        isLoading ||
                        isUploading
                    }
                >
                    Valider
                </Button>
                <Button onClick={handleClose} color="primary" variant="outlined" disabled={isLoading}>Annuler</Button>
            </DialogActions>
        </Dialog>
    </>;
}

export default FileForm;
