import {makeStyles} from '@material-ui/styles';
import {Button, FormControl, Grid, TextField, Typography} from '@material-ui/core';
import {useState} from 'react';
import Link from '../../component/Link/Link';
import {useClient} from '../../app/Ged';
import {Alert, AlertTitle} from '@material-ui/lab';
import {useSelector} from "react-redux";
import {selectSettings} from "../Settings/settingsSlice";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(8, 'auto'),
        maxWidth: 800,
    },
    form: {
        margin: theme.spacing(4, 'auto'),
    },
    formControl: {
        margin: theme.spacing(4, 'auto'),
    },
    link: {
        fontSize: '80%',
    }
}));

export default function PasswordResetRequestForm() {
    const classes = useStyles();
    const [orderNumber, setOrderNumber] = useState(null);
    const {request, isLoading, error} = useClient();
    const [requestSent, setRequestSent] = useState(false);
    const settings = useSelector(selectSettings);

    async function handleSubmit(e) {
        e.preventDefault();

        await request({
            method: 'post',
            url: '/api/v2/users/ask_password_reset',
            data: {username: orderNumber},
        });

        setRequestSent(true);

        return false;
    }

    return <div className={classes.root}>

        <h1>Mot de passe oublié ?</h1>

        <form className={classes.form} onSubmit={handleSubmit}>
            {
                !requestSent && <>
                    <Typography>
                        Remplissez le formulaire suivant afin de procéder à la ré-initialisation de votre mot de passe. <br/>
                        Un email vous sera envoyé pour procéder à la ré-initialisation de votre mot de passe.
                    </Typography>

                    <Grid container justify="center" spacing={2} alignItems="center">
                        <Grid item xs={12} md={5}>
                            <FormControl className={classes.formControl}>
                                <TextField value={orderNumber || ''} label={settings.document_share_name_input_label} onChange={e => setOrderNumber(e.target.value === '' ? null : e.target.value)} />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="center">
                        <Grid item xs={12} md={5}>
                            <Button type="submit" variant="contained" color="primary" disabled={orderNumber === null || isLoading} fullWidth>Ré-initialiser mon mot de passe</Button>
                        </Grid>
                    </Grid>
                    {
                        error && <Grid container justify="center" spacing={2} alignItems="center">
                            <Grid item xs={12} md={5}>
                                <Alert severity="error"><AlertTitle>{error}</AlertTitle></Alert>
                            </Grid>
                        </Grid>
                    }
                </>
            }
            {
                requestSent && <Grid container justify="center" spacing={2} alignItems="center">
                    <Grid item xs={12} md={12}>
                        <Alert severity="success"><AlertTitle>Un email pour ré-initialiser votre mot de passe vous a été envoyé.</AlertTitle></Alert>
                    </Grid>
                </Grid>
            }
        </form>

        <p style={{textAlign: 'center'}}>
            <Link to="/" size="sm">Retour au formulaire de connexion</Link>
        </p>

    </div>;
}
