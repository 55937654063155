import {Button, FormControl, Grid, TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {useState} from 'react';
import {Alert, AlertTitle} from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    formControl: {
        marginBottom: theme.spacing(1)
    },
    button: {
        marginTop: theme.spacing(4)
    }
}));

export default function PasswordResetForm({callback, error, isLoading}) {
    const classes = useStyles();
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    function isFormValid() {
        return password.length > 0 && passwordRepeat.length > 0 && password === passwordRepeat && !isLoading;
    }

    function handleSubmit(e) {
        e.preventDefault();
        callback({password});
        return false;
    }

    return <form onSubmit={handleSubmit}>
        <Grid container justify="center" spacing={2} alignItems="center">
            <Grid item xs={12} md={5}>
                <FormControl className={classes.formControl}>
                    <TextField label="Nouveau mot de passe" value={password} onChange={e => setPassword(e.target.value)} type="password"/>
                </FormControl>
            </Grid>
        </Grid>
        <Grid container justify="center" spacing={2} alignItems="center">
            <Grid item xs={12} md={5}>
                <FormControl className={classes.formControl}>
                    <TextField label="Répéter le nouveau mot de passe" value={passwordRepeat} onChange={e => setPasswordRepeat(e.target.value)} type="password"/>
                </FormControl>
            </Grid>
        </Grid>
        <Grid container justify="center" spacing={2} alignItems="center">
            <Grid item xs={12} md={5}>
                <Button type="submit" variant="contained" color="primary" fullWidth className={classes.button} disabled={!isFormValid()}>Envoyer</Button>
            </Grid>
        </Grid>
        {
            error && <Grid container justify="center" spacing={2} alignItems="center">
                <Grid item xs={12} md={5}>
                    <Alert severity="error"><AlertTitle>{error}</AlertTitle></Alert>
                </Grid>
            </Grid>
        }
    </form>
}
