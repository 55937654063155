import Documents from './Documents';
import FileInfo from './FileInfo';
import {makeStyles} from '@material-ui/core/styles';
import useDocumentShare from './useDocumentShare';
import WelcomeMessage from './WelcomeMessage';
import {Grid} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    wrapper: {
        margin: theme.spacing(0, 'auto'),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0),
            maxWidth: 800,
        },
    },
    container: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse',
        }
    }
}));

function ClientFile() {
    const classes = useStyles();
    const {documentShare} = useDocumentShare();

    return <div>
        {
            documentShare && <div className={classes.wrapper}>
                <Grid container justify={'space-between'} className={classes.container}>
                    <Grid item xs={12} sm={6}>
                        <WelcomeMessage/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FileInfo/>
                    </Grid>
                </Grid>
                <Documents/>
            </div>
        }
    </div>
}

export default ClientFile;
