import useSettings from '../Settings/useSettings';
import {createMuiTheme} from '@material-ui/core';

export default function useTheme() {
    const {settings} = useSettings();

    return {
        theme: createTheme(settings),
    }
}

const createTheme = settings => createMuiTheme({
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        primary: {
            main: settings.colors.primary,
        },
        secondary: {
            main: settings.colors.secondary,
        },
        warning: {
            main: settings.colors.warning,
        },
        error: {
            main: settings.colors.error,
        },
        info: {
            main: settings.colors.info,
        },
        success: {
            main: settings.colors.success,
        }
    },
    overrides: {
        MuiFormControl: {
            root: {
                width: '100%',
            },
        },
        MuiOutlinedInput: {
            root: {
                backgroundColor: settings.colors.bgLight,
                width: '100%',
                minWidth: '260px',
            },
            notchedOutline: {
                borderWidth: '2px',
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: '.8rem',
            },
        },
        MuiInputBase: {
            root: {
                borderWidth: 4,
            },
        },
        MuiDataGrid: {
            root: {
                border: 'none',
                '& .MuiDataGrid-colCellTitle': {
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    textAlign: 'center',
                    color: settings.colors.secondary,
                    fontSize: '.9em',
                },
                '& .alignRight': {
                    justifyContent: 'flex-end',
                },
                '& .MuiButtonGroup-groupedTextHorizontal:not(:last-child)': {
                    border: 0,
                    borderRadius: '.5em',
                },
            },
        },
    },
});
