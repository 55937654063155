import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Footer from './component/Footer';
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import {selectToken, logout} from './features/Login/loginSlice';
import {useDispatch, useSelector} from 'react-redux';
import Header from './component/Header';
import useTheme from './features/Theme/useTheme';
import useLogin from './features/Login/useLogin';
import {Switch, Route, Redirect} from 'react-router-dom';
import {LoginPage, PasswordResetRequestPage, PasswordResetPage, PortalPage, ActivatePage, LogoutPage} from './pages';
import {Helmet} from "react-helmet";
import {selectSettings} from "./features/Settings/settingsSlice";

const useStyle = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        width: "100%",
        height: "100%",
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
}));

function App() {
    const classes = useStyle();
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const {theme} = useTheme();
    const settings = useSelector(selectSettings);
    useLogin();

    if (!theme) {
        return <></>
    }

    return (
        <>
            {settings?.company_name ?
                <Helmet>
                    <title>{`${settings.company_name} - Portail Client`}</title>
                </Helmet> : ''
            }
            <Router>
                <ThemeProvider theme={theme}>
                    <Router>
                        <div className={classes.root} style={settings?.background_image_url ? {backgroundImage:`url(${settings.background_image_url})`} : {}}>
                            {
                                token ? <>
                                    <Header variant="" handleLogout={() => dispatch(logout())}/>
                                    <Switch>
                                        <Route exact path="/">
                                            <PortalPage/>
                                        </Route>
                                        <Route path="/activate">
                                            <LogoutPage/>
                                        </Route>
                                        <Redirect push to="/" />
                                    </Switch>
                                </> : <>
                                    <Header variant="simple" handleLogout={() => dispatch(logout())}/>
                                    <Switch>
                                        <Route exact path="/">
                                            <LoginPage/>
                                        </Route>
                                        <Route path="/activate">
                                            <ActivatePage/>
                                        </Route>
                                        <Route path="/mot-de-passe-oublié">
                                            <PasswordResetRequestPage/>
                                        </Route>
                                        <Route path="/reset_password">
                                            <PasswordResetPage/>
                                        </Route>
                                        <Redirect push to="/" />
                                    </Switch>
                                </>
                            }
                            <Footer/>
                        </div>
                    </Router>
                </ThemeProvider>
            </Router>
        </>
    );
}

export default App;
